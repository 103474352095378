video.webcamEnabledContainer {
  /*display: none;*/
  visibility: hidden;
}
.chip-label * {
  font-size: 10px !important;
}
.stepButton.answered.bookmarked svg {
  color: orange !important;
}
.stepButton.answered svg {
  color: green !important;
}
.stepButton.bookmarked svg {
  color: red !important;
}
.quiz {
  background-color: transparent; 
}
.quiz:hover, .profileCard:hover {
  /*box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;*/
}
.quiz:hover, .profileCard:hover {
}
.quiz, .profileCard {
  border: 1px solid #ccc;
  border-radius: 0 !important;
  margin: auto;
  box-shadow: none !important;
}
.quizDesktopControls {
  position: fixed;
  margin-left: 30px;
  margin-right: 30px;
  padding: 16px;
  border: 1px solid #ccc;
  width: calc(100% - 60px);
  z-index: 999;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.quizQuestionDescription * {
  font-size: .875rem!important;
}
.quizQuestionOptions {
  padding-left: 16px;
  padding-right: 16px;
}
.quizQuestionDescription, .quizQuestionOptions {
  text-align: left!important;
  max-height: 280px;
  overflow-y: auto;
}
.quizQuestionDescription {
  padding: 16px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}
.quizTimer {
  font-size: 24px;
  display: block;
  padding: 16px;
}
.dot {
  width: 8px;
  height: 8px;
  margin-top: 8px;
  background-color: lightgray;
  border-radius: 50%;
}
.quizTimerContainer {
  border: 1px solid #888;
  border-radius: 0 !important;
  display: block;
  height: calc(100% - 5px);
  margin-left: 5px;
}
@media only screen and (max-width: 600px) {
  .quizTimer {
    margin: 5px;
  }
}
.quizCategoryName * {
  font-size: 12px !important;
}
.quizDescription {
  max-width: 100%;
  overflow-x: hidden !important;
}
.quizDescription svg {
  font-size: 28px !important;
}
.quizDescription * {
  font-size: 14px !important;
}
.quizSummary * {
  font-size: 20px !important;
}
.redirectCancelBtn * {
  font-size: 12px !important;
}
.redirectCancelBtn {
  width: 40px;
}
.quizSummary {
  padding: 10;
  text-align: left;
  margin: 15px;
}
.yourSuggestion {
  padding: 10;
  text-align: left;
}
.albumTitleName {
  font-family: 'Aclonica', sans-serif !important;
  font-size: 38px !important;
}
.quizFilterTabs * {
  font-size: 14px !important;
  padding: 2px !important;
}
.quiz.block {
  opacity: 1 !important;
  max-height: 500px !important;
  max-width: 380px !important;
  transition: opacity 0.5s ease-in, max-width 0.2s ease-in, max-height 0.2s ease-in;
}
@media only screen and (min-width: 400px) {
  .quiz.block {
    min-width: 320px;
  }
}
.quiz.none {
  opacity: 0 !important;
  max-width: 0 !important;
  max-height: 0 !important;
  transition: max-width 0.5s ease-out, max-height 0.5s ease-out, opacity 0.2s ease-out;
}
.examContainer {
  display: inline-flex !important;
  margin: 5px !important;
  border-radius: 5px !important;
}
.examContainer:hover {
}
.examContainer.selected {
  background-color: #212121 !important;
  color: white !important;
}
.examContainer.selected p, .examContainer.selected h3 {
  color: white !important;
}
.react-countdown-clock canvas{
  background: #212121;
  border-radius: 50px;
}
.instructions {
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.v-center {
 display: flex;
 align-items: center;
}
.welcomeUserActionRoot > * {
  width: 100%;
}

#clickToUploadDiv {
  position: relative;
  width: 100%;
}
#clickToUploadDiv #clickToUploadText {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 18px;
  padding-top: 67px;
}
#clickToUploadDiv:hover #clickToUploadText {
  display: block;
}
#clickToUploadDiv:hover #clickToUploadAvatar {
  opacity: 0.5;
}
.circularProgress {
  overflow: hidden;
  font-style: italic;
  width: 50%;
  height: 50%;
  top: 50%;
  text-align: center !important;
  -webkit-transform: translateY(50%) translateX(50%);
  -ms-transform: translateY(50%) translateX(50%);
  transform: translateY(50%) translateX(50%);
}
.nodatafound {
  color: #aaa !important;
  font-style: italic;
  width: 100%;
  height: 50%;
  overflow: hidden;
  top: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.individualUserArray {
  background-color: #bdbdbd;
  padding: 0;
  padding-bottom: 50px;
  border-left: 2px solid white;
  border-right: 2px solid white;
  box-shadow: 10px 0px 75px 2px #888888 inset;
}
.individualUserArrayVert {
  vertical-align: middle;
  padding-top: 75px;
}
.topPerformersCard {
  position: fixed !important;
  bottom: 0 !important;
  width: 33% !important;
  right: 50px !important;
  z-index: 100;
}
.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}
.stickyCardHeader {
  position: fixed;
  top: 70px;
  left: 0px;
  z-index: 1;
  background: white;
  margin: 0 24px;
  width: calc(100% - 54px);
}
.react-swipeable-view-container > div[aria-hidden="false"] {
  height: 100%;
}

.react-swipeable-view-container > div[aria-hidden="true"] {
  height: 0;
}

/* width */
*[class^=.MuiBottomNavigation-root] {
  background-color: #f5f5f5;
}
*[class^=MuiCardHeader-root] {
  border-bottom: 1px solid rgb(204, 204, 204);
}
.cardMediaImages {
  height: 194px;
  background-size: contain !important;
}
main img {
  max-width: 100% !important;
}
.RankingCategoryCard {
  text-align: left !important;
}
*:focus {
  outline: 0 !important;
}
.fb-login-button {
  border: 0;
  color: #fff;
  margin: 15px 0px;
  padding: 8px 16px;
  min-width: 88px;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4; 0; 0.2; 1) 0ms;
  background-color: #3f51b5;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  line-height: 1.4em;
  font-family: "Roboto", "Helvetica" "Arial" sans-serif;
  font-weight: 500;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100%
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #212121; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #212121;
  cursor: pointer;
}

.App {
  text-align: center;
}

.white-snack-bar > div {
  background-color: transparent;
  box-shadow: none;
}
.marginedNotePanel {
  margin-top: 55px;
  padding-bottom: 70px;
  max-width: 25%;
  width: 25%;
}
.profilePic {
  width: 194px !important;
  height: 194px !important;
  margin: auto;
}
@media only screen and (max-width: 350px) {
  .profilePic {
    width: 100% !important;
    height: auto !important;
  }
}
@media only screen and (max-width: 600px) {
  .marginedNotePanel {
    max-width: 100%;
    width: 100%;
  }
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.inline {
  display: inline-block !important;
}
.cardWithMargins {
  margin: 15px;
}

.containerWithMargin {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 70px;
}
.mainArea {
  margin-top: 55px;
}
.quizCard {
  margin: 15px;
  max-width: 320px;
  width: 320px;
}
@media only screen and (max-width: 340px) {
  #MainLessonView {
    padding: 0px !important;
  }
  #LessonCardHeader {
    margin: 0px !important;
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .quizCard {
    max-width: 100%;
    width: calc(100% - 30px);
  }
  .cardWithMargins {
    width: 96%;
    max-width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .containerWithMargin {
    margin-left: 0;
    margin-right: 0;
  }
}

.reportTyprography {
  padding: 0 !important;
  padding-top: 10px !important;
}
@media only screen and (max-width: 600px) {
  .reportTyprography {
    padding: 10px !important;
  }
}
.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.question {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%
}
.questionTitle {
  padding: 15px;
  border-radius: 5px;
  background-color: #ddd;
}

body {
  background: #EEEEEE;
}

.titleName {
  font-family: 'Aclonica', sans-serif !important;
  font-size: 28px !important;
}
.titleFirstName {
  font-family: 'Aclonica', sans-serif !important;
  font-size: 35px !important;
}
* {
  /*font-family: 'Rubik', sans-serif !important;*/
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 16px;
}

.questionDesc {
  padding: 5px;
  border: 1px solid #ddd; 
}

.signinContainer {
  padding: 20px;
}

#user-container > * {
  padding: 5px;
  text-align: left;
  margin: 5px;
}

.form-control {
  margin-bottom: 10px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes maximize {
  from { height: auto; }
  to { height: 0; }
}

@keyframes minimize {
  from { height: 0; }
  to { height: auto; }
}

.ratingDialog {
  text-align: left;
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 3000;
  box-shadow: rgb(136, 136, 136) 0px 0px 15px;
}
.ratingDialog.block{
  max-height: 500px;
  transition: max-height 0.5s ease-out;
}
.ratingDialog.none{
  max-height: 0;
  transition: max-height 0.5s ease-in;
}
@media only screen and (max-width: 500px) {
  .ratingDialog {
    text-align: left;
    position: fixed;
    width: 100%;
    right: 0;
    z-index: 1000;
    box-shadow: rgb(136, 136, 136) 0px 0px 15px;
  }
  .ratingDialog.block{
    bottom: 0px;
    max-height: 500px;
    transition: max-height 0.5s ease-out;
  }
  .ratingDialog.none{
    max-height: 0;
    transition: max-height 0.5s ease-in;
  }
}
#root {
  max-width: 100%;
  width: 100%;
  background: #EEEEEE;
}
button[class*='flatSecondary'] {
  background-color: #e0e0e0 !important;
}
pre code {
  font-family: monospace !important;
}
.hljs-ln-numbers {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: center;
  color: #ccc;
  border-right: 1px solid #CCC;
  vertical-align: top;
  padding-right: 5px;
}
.hljs-ln-code {
  padding-left: 10px;
}